.invoice-form-container {
  display: flex;
  gap: 18px;
  flex-direction: column;
}

.invoice-form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
}

.invoice-toggle {
  padding-top: 20px;
}

.invoice-form-title {
  margin-bottom: 20px;
}

.invoice-form-distance-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
