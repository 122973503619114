.not-supported {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;

  width: 100vw;
  height: 100vh;
  text-align: center;
  background-image: url('../../img/old_computer.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  font-family: Rajdhani, sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: darkred;

  &.open {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

#not-supported-main-title {
  margin-bottom: 16px;
  font-size: 24px;
  color: white;
  z-index: 10002;
}

#not-supported-title {
  font-size: 20px;
  color: white;
  font-weight: 400;
  line-height: 1.5;
  z-index: 10002;
  margin-bottom: 32px;
}

.not-supported-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  background-color: #272e35;
  z-index: 10001;
}

#browsers {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  z-index: 10002;
}

.browsers-list {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  //z-index: 10002;
}

.browsers-item {
  margin: 0 18px;
}

.browser-img {
  display: block;
  margin: 0 auto;
  cursor: pointer;
  width: 50px;
  height: 50px;
  text-align: center;
  margin-bottom: 12px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.4s ease-in;

  &:hover {
    transform: scale(0.9);
  }
}

#browser-chrome-img {
  background-image: url('../../img/chrome-7.svg');
}

#browser-safari-img {
  background-image: url('../../img/safari-3.svg');
}

#browser-mozilla-img {
  background-image: url('../../img/firefox-3.svg');
}

#browser-edge-img {
  background-image: url('../../img/microsoft-edge.svg');
}

.browser-title {
  font-size: 12px;
  font-weight: normal;
  color: white;
}
