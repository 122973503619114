@font-face {
  font-family: 'Aeonik';
  src: url('../../fonts/aeonik/Aeonik-Black.otf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../fonts/aeonik/Aeonik-BlackItalic.otf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../fonts/aeonik/Aeonik-Bold.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../fonts/aeonik/Aeonik-BoldItalic.otf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../fonts/aeonik/Aeonik-Medium.otf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../fonts/aeonik/Aeonik-MediumItalic.otf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../fonts/aeonik/Aeonik-Regular.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../fonts/aeonik/Aeonik-RegularItalic.otf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../fonts/aeonik/Aeonik-Light.otf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../fonts/aeonik/Aeonik-LightItalic.otf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
